import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { HeroText } from '../components/HeroText';

import imageTeamMarkus from '../images/markus.jpg'
import imageTeamMathias from '../images/mathias.jpg'
import imageTeamAndreas from '../images/andreas.jpg'
import imageTeamStefan from '../images/stefan.jpg'
// import imageTeamKathi from '../images/kathi.jpg'

const styleImageUsability = { borderRadius: '100%' };

const UberUnsPage = () => (
  <Layout activeMenuItem={'uber-uns'}>
    <Seo title="Usability" keywords={[`design`, `strategisches design`]} />

    <HeroText>Wir sind ein 4-köpfiges Team aus Graz, spezialisiert auf Interface-Design, Usability und
      User-Experience.</HeroText>

    <div className="content-wrapper team">
      <div className="content pure-g pure-g-center">
        <div className="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
          <img src={imageTeamMarkus} style={styleImageUsability} alt="Markus Pirker" />
          <h3>Markus Pirker</h3>
          <a href="mailto:markus@simplease.at" className="mail">markus@simplease.at</a>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
          <img src={imageTeamMathias} style={styleImageUsability} alt="Mathias Placho" />
          <h3>Mathias Placho</h3>
          <a href="mailto:mathias@simplease.at" className="mail">mathias@simplease.at</a>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
          <img src={imageTeamAndreas} style={styleImageUsability} alt="Andreas Riedmüller" />
          <h3>Andreas Riedmüller</h3>
          <a href="mailto:andreas@simplease.at" className="mail">andreas@simplease.at</a>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
          <img src={imageTeamStefan} style={styleImageUsability} alt="Stefan Rössler" />
          <h3>Stefan Rössler</h3>
          <a href="mailto:stefan@simplease.at" className="mail">stefan@simplease.at</a>
        </div>
{/*        <div className="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
          <img src={imageTeamKathi} style={styleImageUsability} alt="Katharina Veit" />
          <h3>Katharina Veit</h3>
          <a href="mailto:kathi@simplease.at" className="mail">kathi@simplease.at</a>
        </div>*/}
      </div>
    </div>

    <div className="content-wrapper secondary">
      <div className="content pure-g">
        <div className="l-box pure-u-1 pure-u-md-1-2 l-left">
          <p className="init">Wir glauben, Design und Usability gehören zusammen. Genauso, wie Design und Programmierung
            zusammen gehören. Warum? Weil alles zusammenspielen muss, um eine sinnvolle und erstklassige User-Experience
            zu ermöglichen.</p>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2 l-left">
          <p>Wir bringen uns strategisch in Ihr Projekt ein und lernen Ihre Kunden und Ihr Geschäft kennen. Wir
            entwickeln konkrete Lösungsvorschläge, liefern getestete Prototypen und zeigen Ihren Mitarbeitern, wie sie
            all das selbst machen.</p>
        </div>
      </div>
    </div>

    <div className="content-wrapper" id="our-stuff">
      <div className="content pure-g">
        <div className="l-box pure-u-1 pure-u-md-1-3">
          <h3>Unsere iOS-Apps</h3>
          <p>Unsere Apps <a href="https://itunes.apple.com/de/app/touch-me-lite/id391328344?mt=8">Touch
            Me</a>, <a href="https://itunes.apple.com/de/app/zeroapp/id518000104?mt=8">Zero</a> und <a
            href="https://itunes.apple.com/de/app/what-says-.../id471990518?mt=8">Wie macht
            …?</a> wurden über eine halbe Million Mal heruntergeladen, <a
            href="https://itunes.apple.com/de/app/flip-stones/id587378433?mt=8">Flip Stones</a> wurde
            von futurezone.at zum Spiel des Jahres nominiert.</p>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-3">
          <h3>Userbrain</h3>
          <p>Mit Userbrain können Sie Ihre <a href="https://userbrain.net/">Usability-Tests ganz einfach selbst
            durchführen</a>. Perfekt für User-Interface-Designer, Frontend-Developer, Online-Händler
            und&nbsp;Usability-Experten.</p>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-3">
          <h3>Simplease-Blog</h3>
          <p>Seit 2010 schreiben wir über Design, Usability, Web-Entwicklung und unser Leben als Selbstständige. <a
            href="http://blog.simplease.at">Lesen Sie unseren Blog</a> und erfahren Sie mehr darüber, wie wir denken.
          </p>
        </div>
      </div>
    </div>

    <div className="content-wrapper secondary" id="kontakt">
      <div className="content pure-g">
        <div className="l-box pure-u-1">
          <h2 className={'hero-heading'}>Wir freuen uns von Ihnen zu hören. Schreiben Sie uns eine E‑Mail oder rufen Sie
            an und erzählen Sie von
            Ihrer Idee.</h2>
        </div>
      </div>
      <div className="content pure-g">
        <div className="l-box pure-u-1 l-center">
          <p className="contact-info">
            <a href="mailto:office@simplease.at" style={{ whiteSpace: 'nowrap' }}>
              <i className="fas fa-envelope" />office@simplease.at</a><br />
            <i className="fas fa-phone" />+43 316 455065</p>
        </div>
      </div>
      <div className="content pure-g">
        <div className="l-box pure-u-1 l-center">
          <h3>Simplease OG</h3>
          <p>Frauengasse 7<br />8010 Graz, AUSTRIA<br /><a
            href="https://goo.gl/maps/52DZEyYPEDCUMh7S9"
          >
            Auf Google-Maps anzeigen</a></p>
          <ul className="social">
            <li>Folgen Sie uns auf</li>
            <li><a href="https://www.facebook.com/simplease" aria-label="Open Simplease on Facebook">
              <i className="fab fa-facebook-square" /></a></li>
            <li><a href="https://twitter.com/simplease" aria-label="Open Simplease on Twitter"><i className="fab fa-twitter-square" /></a>
            </li>
          </ul>
          <a href="http://flip.it/WFVXc">Unser UX-Magazin auf Flipboard</a>
        </div>
      </div>
    </div>
  </Layout>
);

export default UberUnsPage;
